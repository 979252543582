import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';
import { useStaticQuery, graphql } from "gatsby"

const About = () => {
  const data = useStaticQuery(graphql`
  {
    contentfulAboutPage {
      headline
      slug
      aboutSection {
        headline
        description {
          description
        }
        aboutList {
          objectiveList
        }
        headline
      }
      objectives {
        heading{
          heading
        }
        objectiveList {
          icon
          title
          description
        }
        downloadFile {
          headline
          subHeadline
          downloadButtonText
          downloadFile {
            file {
              url
            }
          }
        }

      }
      keySection {
        ketSectorList {
          keySectorList
        }
        name
      }
      supportSection {
        name
        supportAreaList {
          supportAreaList
        }
      }
    }
  }
`)

  const objectiveHeading = data.contentfulAboutPage.aboutSection;
  const objective = data.contentfulAboutPage.aboutSection.aboutList;
  const aboutDesc = data.contentfulAboutPage.aboutSection.description;
  const supportName = data.contentfulAboutPage.supportSection;
  const support = data.contentfulAboutPage.supportSection.supportAreaList;
  const keyName = data.contentfulAboutPage.keySection;
  const keySection = data.contentfulAboutPage.keySection.ketSectorList;
  const objectives = data.contentfulAboutPage.objectives.heading;
  const list = data.contentfulAboutPage.objectives.objectiveList;
  const download = data.contentfulAboutPage.objectives.downloadFile;
  return (
    <Layout>
      <SEO title="About" />
      <div className="sidebar-page-container">
        <div className="auto-container">
          <div className="sec-title">
            <h2>About The <span>Tekki Fii</span> Program</h2>
            <div className="title">{objectives.title}</div>
          </div>
          <div className="row clearfix">
            <div className="content-side col-lg-9 col-md-12 col-sm-12">
              <div className="service-detail">
                <div className="inner-box">
                  <div className="lower-content">
                    <div className="row clearfix">
                      {list.map(({ icon, title, description }, index) => (
                        <div className="service-block style-two col-lg-4 col-md-6 col-12">
                          <div className="inner-box" key={index}>
                            <div className="icon-box">
                              <span className={icon}></span>
                            </div>
                            <h5><a href="service-detail.html">{title}</a></h5>
                            <div className="text">{description}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="column col-lg-12 col-md-12 col-sm-12">
                      <p style={{ fontSize: 17 }}>
                        {objectives.heading}
                      </p>
                    </div>
                    <div className="analysis-columns" style={{ marginTop: '60px' }}>
                      <div className="row clearfix">
                        <div className="column col-lg-12 col-md-12 col-sm-12">
                          <h4>{objectiveHeading.headline}</h4>
                          <p>{aboutDesc.description}</p>
                          <ul className="list-style-one">
                            {objective.map(({ objectiveList }, index) => (
                              <li key={index}>{objectiveList}</li>
                            ))}
                          </ul>
                        </div>
                        <div className="column col-lg-6 col-md-6 col-sm-12" style={{ marginTop: 30 }}>
                          <h4>{supportName.name}</h4>
                          <ul className="list-style-one">
                            {support.map(({ supportAreaList }, index) => (
                              <li key={index}>{supportAreaList}</li>
                            ))}
                          </ul>
                        </div>
                        <div className="column col-lg-6 col-md-6 col-sm-12" style={{ marginTop: 30 }}>
                          <h4>{keyName.name}</h4>
                          <ul className="list-style-one">
                            {keySection.map(({ keySectorList }, index) => (
                              <li key={index}>{keySectorList}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sidebar-side col-lg-3 col-md-12 col-sm-12">
              <aside className="sidebar sticky-top">
                <div className="sidebar-widget enquiry-widget">
                  <div className="widget-content">
                    <h4>{download.headline}</h4>
                    <div className="text">{download.subHeadline}</div>
                    <div className="number">---------------------------</div>
                    <a href={download.downloadFile.file.url} className="theme-btn btn-style-two"><span className="txt">{download.downloadButtonText}</span></a>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
